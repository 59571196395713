import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'
import WastewaterAudit from '@components/wastewater-audit'
import MustRead from '@components/must-read'

const FoodAndBeveragePage = () => {
  return (
    <Layout pageTitle="Food and Beverage">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../media/food_and_beverage.jpg"
                alt=""
                aspectRatio={2 / 1}
                objectPosition="center top"
                layout="fullWidth" 
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>FOOD AND BEVERAGE</h2>
                <h1>
                  Say good bye to sewer surcharges
                </h1>
                <h3>
                  Your focus is on production. Our focus is on what's left over. Working together, we'll reduce your costs of doing business.
                </h3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <h3>WE'RE AT YOUR SERVICE</h3>
              <p>
                Water is critical to food and beverage manufacturers and food processors. Wastewater is just a pain. We put our creative problem-solving and innovative technology to work to maximize your efficiency, minimize your costs, ensure your regulatory compliance and turn your waste into value.
              </p>
              <ul>
                <li>
                  <div>
                    Onsite removal of more than 90% of organic contaminants, including suspended solids; fats, oil and grease; and BOD
                  </div>
                </li>
                <li>
                  <div>
                    Onsite or off-site wastewater treatment for disposal or reuse
                  </div>
                </li>
                <li>
                  <div>
                    Wastewater storage tank maintenance and repair
                  </div>
                </li>
                <li>
                  <div>
                    Pond and lagoon management
                  </div>
                </li>
                <li>
                  <div>
                    Consultation on beneficial reuse of food waste
                  </div>
                </li>
                <li>
                  <div>
                    Advice on process and facility changes to reduce costs
                  </div>
                </li>
                <li>
                  <div>
                    Hydovac services
                  </div>
                </li>
                <li>
                  <div>
                    Real-time capacity monitoring during pumping
                  </div>
                </li>
                <li>
                  <div>
                    24/7 emergency response
                  </div>
                </li>
              </ul>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/food_and_beverage_service.png"
                alt="a platter of grapes, cheese, and wine" />
            </Col>
          </Row>
        </Container>
      </main>
      <Container fluid className="section text-center must-read">
        <Container fluid="xxl">
          <Row xl={2} lg={2} md={2} sm={1} xs={1} className="align-items-center">
            <Col>
              <StaticImage 
                src="../../media/food_and_beverage_chocolate.png"
                alt="a close up of 4 pieces of chocolate stacked on top of eachother" />
            </Col>
            <Col>
              <h1>TOO SWEET TO WASTE</h1>
              <p>
                We helped one of Ontario's leading chocolate manufacturers eliminate more than $1M in annual sewer surcharges. Find out how.
              </p>
                <Button href='/case-studies/wastewater-approach-reduces-sewer-charges' className="shadow">READ THE CASE STUDY</Button>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid="xxl" className="section text-center">
        <Row>
          <Col>
            <h3>WE CAN HELP YOU</h3>
          </Col>
        </Row>
        <Row lg={6} md={4} sm={2} xs={2}>
          <Col>
            <StaticImage 
              src="../../media/distilleries.png"
              alt="bottle"
              className="pad" />
            <h5>DISTILLERIES</h5>
          </Col>
          <Col>
            <StaticImage 
              src="../../media/softdrinks.png"
              alt="soda can"
              className="pad" />
            <h5>SOFT DRINK MANUFACTURERS</h5>
          </Col>
          <Col>
            <StaticImage 
              src="../../media/breweries.png"
              className="pad" />
            <h5>BREWERIES</h5>
          </Col>
          <Col>
            <StaticImage 
              src="../../media/candy.png"
              className="pad" />
            <h5>CANDY</h5>
          </Col>
          <Col>
            <StaticImage 
              src="../../media/dairy.png"
              className="pad" />
            <h5>DAIRY</h5>
          </Col>
          <Col>
            <StaticImage 
              src="../../media/wineries.png"
              className="pad" />
            <h5>WINERIES</h5>
          </Col>
          <Col>
            <StaticImage 
              src="../../media/bakery.png"
              className="pad" />
            <h5>BAKERIES</h5>
          </Col>
          <Col>
            <StaticImage   
              src="../../media/meatandseafood.png"
              className="pad" />
            <h5>MEAT AND AQUACULTURE</h5>
          </Col>
          <Col>
            <StaticImage 
              src="../../media/fruitandveg.png"
              className="pad" />
            <h5>FRUIT AND VEGETABLES</h5>
          </Col>
          <Col>
            <StaticImage 
              src="../../media/animalfood.png"
              className="pad" />
            <h5>ANIMAL FOOD</h5>
          </Col>
          <Col>
            <StaticImage 
              src="../../media/grainandoilseed.png"
              className="pad" />
            <h5>GRAIN AND OIL SEED</h5>
          </Col>
        </Row>
      </Container>
      <WastewaterAudit />
      <MustRead />
    </Layout>
  )
}

export default FoodAndBeveragePage